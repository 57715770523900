<template>
  <div class="apps">
    <Header></Header>
    <div class="download-platform-box">
      <div
        class="download-platform"
        @click="changePlatform(0)"
        :class="currentPlatform === 0 ? 'active-platform' : ''"
      >
        <img
          src="../assets/icons/apps-android.svg"
          class="download-platform-icon-android"
        />
        <span>Android</span>
      </div>
      <div
        class="download-platform"
        @click="changePlatform(1)"
        :class="currentPlatform === 1 ? 'active-platform' : ''"
      >
        <img
          src="../assets/icons/apps-windows.svg"
          class="download-platform-icon-windows"
        />
        <span>Windows</span>
      </div>
      <div
        class="download-platform"
        @click="changePlatform(2)"
        :class="currentPlatform === 2 ? 'active-platform' : ''"
      >
        <img
          src="../assets/icons/apps-mac.svg"
          class="download-platform-icon-mac"
        />
        <span>Mac</span>
      </div>
      <div
        class="download-platform"
        @mouseleave="showOrHideDownloadIosTip(0)"
        @mouseover="showOrHideDownloadIosTip(1)"
        :class="currentPlatform === 3 ? 'active-platform' : ''"
      >
        <img
          src="../assets/icons/apps-ios.svg"
          class="download-platform-icon-iOS"
        />
        <span class="ios-color">iOS</span>
        <div class="download-ios-tip" v-show="isShowDownloadIosTip">
          <span>coming soon...</span>
        </div>
      </div>
    </div>
    <div class="download-box">
      <div class="download-box-content">
        <div class="download-info">
          <div class="download-title">
          <h1>
            {{
              $t("apps_download_vpn_for_platform", {
                platform: getCurrentPlatformName(),
              })
            }}
          </h1>
          </div>
          <div class="download-subtitle">
            {{
              $t("apps_download_vpn_for_platform_subtitle", {
                platform: getCurrentPlatformName(),
              })
            }}
          </div>
          <div class="download-btn-row">
            <a
              :href="downloadPlatformData[currentPlatform]['download-link']"
              target="_blank"
            >
              <button
                type="button"
                class="
                  download-btn download-btn-space
                  btn-size-format-1
                  btn-radius-format-1
                  btn-border-format-2
                  btn-color-2
                  btn-cursor
                "
              >
                <span>{{ $t("apps_download") }}</span>
              </button>
            </a>
            <router-link
              :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`"
            >
              <button
                type="button"
                class="
                  download-btn download-btn-space
                  btn-size-format-1 btn-radius-format-1 btn-color-1 btn-cursor
                "
              >
                <span>{{ $t("apps_get_premium") }}</span>
              </button>
            </router-link>
          </div>
          <div class="download-tip">
            {{ downloadPlatformData[currentPlatform]["download-tip"] }}
          </div>
        </div>
        <img
          alt="download vpn now"
          :src="downloadPlatformData[currentPlatform]['download-bg']"
          class="download-bg"
        />
      </div>
    </div>
    <div class="install-box">
      <div class="install-title">
      <h2>
        {{
          $t("apps_how_do_i_download", { platform: getCurrentPlatformName() })
        }}
      </h2>
      </div>
      <div class="install-procedure-row">
        <div
          class="install-procedure install-procedure-space"
          v-for="(installProcedure, installIndex) in downloadPlatformData[
            currentPlatform
          ]['install-procedure']"
          :key="`${'install-procedure-' + installIndex}`"
        >
          <div class="install-procedure-left">
            <div
              class="install-procedure-index"
              :class="`${'install-procedure-index-' + installIndex}`"
            >
              {{ installIndex + 1 }}
            </div>
          </div>
          <div class="install-procedure-right">
            <div class="install-procedure-title">
              {{ $t(installProcedure["title"]) }}
            </div>
            <div class="install-procedure-content">
              {{ $t(installProcedure["subtitle"]) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="apps-features-box">
      <div class="apps-features-title">
      <h2>
        {{ $t("apps_the_feature_you_will_love") }}
      </h2>
      </div>
      <div class="apps-features-subtitle">
        {{ $t("apps_the_feature_you_will_love_subtitle") }}
      </div>
      <div class="apps-features">
        <div class="apps-features-item">
          <img
            alt="patented vpn protocol"
            src="../assets/image/apps_features_1.svg"
            class="apps-features-icon"
          />
          <div class="apps-features-item-title">
            {{ $t("apps_the_feature_point1") }}
          </div>
          <div class="apps-features-item-subtitle">
            {{ $t("apps_the_feature_point1_subtitle") }}
          </div>
        </div>
        <div class="apps-features-item">
          <img
            alt="fastest vpn for any devices"
            src="../assets/image/apps_features_2.svg"
            class="apps-features-icon"
          />
          <div class="apps-features-item-title">
            {{
              $t("apps_the_feature_point2", {
                platformDevice: getCurrentPlatformDevice(),
              })
            }}
          </div>
          <div class="apps-features-item-subtitle">
            {{ $t("apps_the_feature_point2_subtitle") }}
          </div>
        </div>
        <div class="apps-features-item">
          <img
            alt="unlimited bandwidth vpn"
            src="../assets/image/apps_features_3.svg"
            class="apps-features-icon"
          />
          <div class="apps-features-item-title">
            {{ $t("apps_the_feature_point3") }}
          </div>
          <div class="apps-features-item-subtitle">
            {{ $t("apps_the_feature_point3_subtitle") }}
          </div>
        </div>
        <div class="apps-features-item">
          <img
            alt="privacy vpn you can trust"
            src="../assets/image/apps_features_4.svg"
            class="apps-features-icon"
          />
          <div class="apps-features-item-title">
            {{ $t("apps_the_feature_point4") }}
          </div>
          <div class="apps-features-item-subtitle">
            {{ $t("apps_the_feature_point4_subtitle") }}
          </div>
        </div>
        <div class="apps-features-item">
          <img
            alt="money back guarantee"
            src="../assets/image/apps_features_5.svg"
            class="apps-features-icon"
          />
          <div class="apps-features-item-title">
            {{ $t("apps_the_feature_point5") }}
          </div>
          <div class="apps-features-item-subtitle">
            {{ $t("apps_the_feature_point5_subtitle") }}
          </div>
        </div>
        <div class="apps-features-item">
          <img
            alt="live support"
            src="../assets/image/apps_features_6.svg"
            class="apps-features-icon"
          />
          <div class="apps-features-item-title">
            {{ $t("apps_the_feature_point6") }}
          </div>
          <div class="apps-features-item-subtitle">
            {{ $t("apps_the_feature_point6_subtitle") }}
          </div>
        </div>
      </div>
      <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`">
        <button
          type="button"
          class="
            btn-size-format-1
            apps-feature-btn
            btn-radius-format-1 btn-color-1 btn-cursor
          "
        >
          <span>{{
            $t("apps_download_shellvpn_to_start_browsing_privately")
          }}</span>
        </button>
      </router-link>
    </div>

    <div class="comment-box">
      <div class="comment-box-title"><h2>First hand experiences</h2></div>
      <div class="comment-box-subtitle">
        Here's how customers feel about ShellVPN:
      </div>
      <div class="comment-list">
        <Comment :commentList="commentList"></Comment>
      </div>
    </div>
    <div class="apps-start-box">
      <div class="apps-start-title">
      <h3>
        {{ $t("apps_download_shellvpn_to_start_browsing_privately") }}
      </h3>
      </div>
      <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`">
        <button
          type="button"
          class="
            apps-start-btn
            btn-size-format-1 btn-radius-format-1 btn-color-1 btn-cursor
          "
        >
          <span>{{ $t("header_get_started") }}</span>
        </button>
      </router-link>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Comment from "@/components/Comment";

export default {
  name: "Apps",
  components: { Header, Footer, Comment },
  data() {
    return {
      commentList: [],
      currentPlatform: 0,
      isShowDownloadIosTip: false,
      downloadPlatformData: {
        0: {
          "download-tip": "",
          "download-link": "#",
          "download-bg": require("../assets/image/apps_download_android_bg.svg"),
          "install-procedure": [
            {
              title: "apps_phone_download_progress1_title",
              subtitle: "apps_phone_download_progress1_subtitle",
            },
            {
              title: "apps_phone_download_progress2_title",
              subtitle: "apps_phone_download_progress2_subtitle",
            },
            {
              title: "apps_phone_download_progress3_title",
              subtitle: "apps_phone_download_progress3_subtitle",
            },
          ],
        },
        1: {
          "download-tip": "Requires Windows 10",
          "download-link": "#",
          "download-bg": require("../assets/image/apps_download_windows_bg.svg"),
          "install-procedure": [
            {
              title: "apps_pc_download_progress1_title",
              subtitle: "apps_pc_download_progress1_subtitle",
            },
            {
              title: "apps_pc_download_progress2_title",
              subtitle: "apps_pc_download_progress2_subtitle",
            },
            {
              title: "apps_pc_download_progress3_title",
              subtitle: "apps_pc_download_progress3_subtitle",
            },
          ],
        },
        2: {
          "download-tip": "",
          "download-link": "#",
          "download-bg": require("../assets/image/apps_download_mac_bg.svg"),
          "install-procedure": [
            {
              title: "apps_pc_download_progress1_title",
              subtitle: "apps_pc_download_progress1_subtitle",
            },
            {
              title: "apps_pc_download_progress2_title",
              subtitle: "apps_pc_download_progress2_subtitle",
            },
            {
              title: "apps_pc_download_progress3_title",
              subtitle: "apps_pc_download_progress3_subtitle",
            },
          ],
        },
        3: {
          "download-tip": "",
          "download-link": "#",
          "download-bg": "",
          "install-procedure": [
            {
              title: "apps_phone_download_progress1_title",
              subtitle: "apps_phone_download_progress1_subtitle",
            },
            {
              title: "apps_phone_download_progress2_title",
              subtitle: "apps_phone_download_progress2_subtitle",
            },
            {
              title: "apps_phone_download_progress3_title",
              subtitle: "apps_phone_download_progress3_subtitle",
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.currentPlatform =
      this.$route.query.platform === undefined
        ? 0
        : parseInt(this.$route.query.platform);
  },
  created() {
    this.getDownloadLink();
  },
  watch: {
    $route: {
      handler() {
        this.currentPlatform =
          this.$route.query.platform === undefined
            ? 0
            : parseInt(this.$route.query.platform);
        window.scroll(0, 0);
      },
      deep: true,
    },
  },
  methods: {
    changePlatform(platform) {
      this.currentPlatform = platform >= 3 ? 0 : platform;
    },
    getCurrentPlatformName() {
      switch (this.currentPlatform) {
        case 0:
          return "Android";
        case 1:
          return "Windows";
        case 2:
          return "macOS";
        case 3:
          return "iOS";
      }
    },
    getCurrentPlatformDevice() {
      switch (this.currentPlatform) {
        case 0:
        case 3:
          return "Phone";
        case 1:
        case 2:
          return "PC";
      }
    },
    showOrHideDownloadIosTip(value) {
      this.isShowDownloadIosTip = !!value;
    },
    getDownloadLink() {
      this.$axios.get("/latest-release").then((response) => {
        this.downloadPlatformData[0]["download-link"] =
          response.data.android === null
            ? "#"
            : response.data.android.downloadDetail.downloadUrl;
        this.downloadPlatformData[1]["download-link"] =
          response.data.windows === null
            ? "#"
            : response.data.windows.downloadDetail.downloadUrl;
        this.downloadPlatformData[2]["download-link"] =
          response.data.mac === null
            ? "#"
            : response.data.mac.downloadDetail.downloadUrl;
        this.downloadPlatformData[3]["download-link"] =
          response.data.ios === null
            ? "#"
            : response.data.ios.downloadDetail.downloadUrl;
      });
    },
  },
};
</script>

<style scoped>
.apps {
  display: flex;
  flex-direction: column;
}

.download-platform-box {
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  flex-wrap: wrap;
}

.download-platform {
  margin: 40px 35px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 14px;
  width: 100px;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 4px solid transparent;
  color: #1d1d1d;
}

.download-platform span {
  margin-top: 10px;
  height: 18px;
  font-size: 14px;
  font-weight: 400;
}

.download-platform-icon-android {
  width: 31px;
  height: 36px;
}

.download-platform-icon-windows {
  width: 35px;
  height: 33px;
}

.download-platform-icon-mac {
  width: 38px;
  height: 33px;
}

.download-platform-icon-iOS {
  width: 21px;
  height: 38px;
}

.ios-color {
  color: #aeaeae;
}

.active-platform {
  color: #4ebe86;
  border-color: #4ebe86;
  fill: #4ebe86;
}

.download-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f8f8;
}

.download-box-content {
  width: 100%;
  max-width: 1100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.download-info {
  max-width: 450px;
  padding: 150px 16px;
}

.download-title h1{
  font-size: 40px;
  font-weight: 500;
  line-height: 49px;
  color: #202f38;
}

.download-subtitle {
  max-width: 449px;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #707070;
  margin-top: 19px;
}

.download-btn-row {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #707070;
  display: flex;
  flex-wrap: wrap;
}

.download-btn span {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
}

.download-btn-space {
  margin: 10px 32px 10px 0px;
}

.download-tip {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #707070;
  margin-top: 20px;
}

.download-bg {
  max-width: 100%;
  height: auto;
}

.install-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
}

.install-title {
  max-width: 1100px;
  font-size: 30px;
  font-weight: bold;
  line-height: 35px;
  color: #2d2d2d;
  margin: 126px auto 0;
  text-align: center;
}

.install-procedure-row {
  margin-top: 70px;
  margin-bottom: 70px;
  max-width: 1280px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.install-procedure {
  display: flex;
}

.install-procedure-index-0 {
  background: #ffc52a;
}

.install-procedure-index-1 {
  background: #609eff;
}

.install-procedure-index-2 {
  background: #ff6085;
}

.install-procedure-space {
  margin: 30px 30px;
}

.install-procedure-left {
  width: 58px;
  height: 88px;
  border-radius: 58px;
  margin-right: 20px;
  font-size: 40px;
  color: #fff;
  font-weight: 900;
  text-align: center;
  background: #f9f8f8;
}

.install-procedure-index {
  width: 58px;
  height: 58px;
  border-radius: 58px;
  font-size: 40px;
  font-family: DIN;
  font-weight: 900;
  line-height: 58px;
  color: #ffffff;
}

.install-procedure-right {
  font-size: 16px;
  color: #707070;
  line-height: 25px;
}

.install-procedure-title {
  max-width: 251px;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  color: #1d1d1d;
}

.install-procedure-content {
  max-width: 251px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #707070;
  margin-top: 16px;
}

.apps-features-box {
  padding: 100px 16px 75px;
  background: rgba(78, 190, 134, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.apps-features-title h2{
  font-size: 40px;
  font-weight: bold;
  line-height: 54px;
  color: #1d1d1d;
  text-align: center;
}

.apps-features-subtitle {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 400;
  color: #1d1d1d;
  text-align: center;
}

.apps-features {
  max-width: 1074px;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.apps-features-item {
  max-width: 358px;
  box-sizing: border-box;
  color: #1d1d1d;
  text-align: center;
}

@media screen and (min-width: 400px) {
  .apps-features-item {
    width: 358px;
  }
}

.apps-features-icon {
  width: 83px;
  height: 83px;
}

.apps-features-item-title {
  margin-top: 20px;
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;
  color: #1d1d1d;
  text-align: center;
}

.apps-features-item-subtitle {
  margin: 20px auto 0;
  max-width: 283px;
  min-height: 147px;
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  color: #1d1d1d;
}

@media screen and (min-width: 1121px) {
  .apps-features-item:nth-child(1) {
    border-bottom: 1px solid #e5e5e5;
  }

  .apps-features-item:nth-child(2) {
    border-left: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
  }

  .apps-features-item:nth-child(3) {
    border-bottom: 1px solid #e5e5e5;
  }

  .apps-features-item:nth-child(4) {
    padding-top: 101px;
  }

  .apps-features-item:nth-child(5) {
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    padding-top: 101px;
  }
}

.apps-features-item:nth-child(6) {
  padding-top: 101px;
}

.apps-feature-btn {
  margin-top: 52px;
  width: auto;
  height: auto;
  padding: 16px 36px;
}

.comment-box {
  padding: 100px 16px;
  background-color: #ffffff;
}

.comment-box-title {
  font-size: 40px;
  font-weight: 500;
  line-height: 49px;
  color: #1f1f1f;
  opacity: 1;
  text-align: center;
}

.comment-box-subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: #707070;
  opacity: 1;
  margin: 18px auto 0;
  text-align: center;
}

.comment-list {
  margin-top: 53px;
}

.apps-start-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 149px 16px;
  background-image: url("../assets/image/apps_start_bg.png");
  background-size: cover;
  background-position: center center;
}

.apps-start-title h3{
  max-width: 651px;
  font-size: 40px;
  font-weight: 500;
  line-height: 49px;
  color: #ffffff;
  text-align: center;
}

.apps-start-btn {
  margin-top: 50px;
}

.download-ios-tip {
  margin-left: 160px;
  width: 88px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.download-ios-tip span {
  width: 53px;
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #2d3b3d;
}
</style>
